import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/header'
import Footer from '../components/footer';

const Privacy = () => {
    // Client-side Runtime Data Fetching
    const [privacy, setprivacy] = useState([])
    useEffect(() => {
        // get data from GitHub api
        fetch(`https://wordpress-906007-3157911.cloudwaysapps.com/wp-json/wp/v2/pages/3`)
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {
                setprivacy(resultData.content.rendered);
            }) 

    }, [])
  
    return (
        <>
        <Header/>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Case Studies - Productlabs</title>
            <link rel="canonical" href="https://productlabs.us/" />
            <meta property="og:title" content="Case Studies - Productlabs" />
            <meta property="og:description" content="VETTED EXPERTS TO HELP EXECUTE PRODUCT BUILD" />
        </Helmet>
        <div className="terms-section">
            <div className="container">
                <div dangerouslySetInnerHTML={{__html: privacy}}></div>
            </div>
        </div>
        <Footer/>
        </>
        
    )
}
 
export default Privacy;